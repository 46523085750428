// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const Navigation = (): VerticalNavItemsType => {
  return [
    {
      sectionTitle: 'Menu'
    },
    {
      title: 'Dashboard',
      icon: 'mdi:chart-bar',
      path: '/dashboard',
      action: 'read',
      subject: 'shared-page'
    },
    {
      title: 'Customers',
      icon: 'mdi:account-group-outline',
      path: '/customers',
      action: 'read',
      subject: 'agent-only-page'
    },
    {
      title: 'Admin',
      icon: 'mdi:account-tie-outline',
      action: 'read',
      subject: 'sa-page',
      children: [
        {
          title: 'Users',
          path: '/admin/users',
          action: 'read',
          subject: 'sa-page'
        },
        {
          title: 'Roles & Permissions',
          path: '/admin/roles',
          action: 'read',
          subject: 'sa-page'
        },
        {
          title: 'Activity Logs',
          action: 'read',
          subject: 'sa-page',
          path: '/admin/activity-logs'
        }
      ]
    },
    {
      title: 'Super Agents',
      icon: 'mdi:account-supervisor-outline',
      action: 'read',
      children: [
        {
          title: 'Sites',
          action: 'read',
          subject: 'sa-page',
          path: '/super-agents/sites'
        },
        {
          title: 'Owners',
          path: '/super-agents/owners'
        },
        {
          title: 'Agents',
          action: 'read',
          subject: 'sa-page',
          path: '/super-agents/agents'
        },
        {
          title: 'Customers',
          action: 'read',
          subject: 'sa-page',
          path: '/super-agents/customers'
        },
        {
          title: 'Whitelist Customers',
          path: '/whitelist-customers'
        }
      ]
    },
    {
      title: 'Content Creators',
      icon: 'mdi:camera-account',
      action: 'read',
      path: '/content-creators'
    },
    {
      title: 'Transactions',
      icon: 'mdi:finance',
      action: 'read',
      subject: 'shared-page',
      children: [
        {
          title: 'Donations',
          path: '/transactions/donations',
          action: 'read',
          subject: 'except-agent-page'
        },
        {
          title: 'Security Funds',
          path: '/transactions/security-funds',
          action: 'read',
          subject: 'sa-page'
        },
        {
          title: 'Withdrawals',
          path: '/transactions/withdrawal',
          action: 'read',
          subject: 'cc-god-only-page'
        },
        {
          title: 'Work Purchases',
          path: '/transactions/work-purchases',
          action: 'read',
          subject: 'except-agent-page'
        },
        {
          title: 'Earnings Transactions',
          action: 'read',
          subject: 'shared-page',
          children: [
            {
              title: 'Content Creator',
              path: '/transactions/earnings/ContentCreator',
              action: 'read',
              subject: 'cc-page'
            },
            {
              title: 'Super Agent',
              path: '/transactions/earnings/SuperAgent',
              action: 'read',
              subject: 'sa-page'
            },
            { title: 'Agent', path: '/transactions/earnings/Agent', action: 'read', subject: 'agent-page' }
          ]
        },
        {
          title: 'VIP Bundles',
          path: '/transactions/vip-bundles',
          action: 'read',
          subject: 'agent-page'
        },
        {
          title: 'Gold Coin Bundles',
          path: '/transactions/gold-coin-bundles',
          action: 'read',
          subject: 'agent-page'
        }
      ]
    },
    {
      title: 'Reports',
      icon: 'mdi:file-chart-outline',
      action: 'read',
      subject: 'shared-page',
      children: [
        {
          title: 'Donations',
          action: 'read',
          path: '/reports/donations',
          subject: 'except-agent-page'
        },
        {
          title: 'Withdrawals',
          path: '/reports/withdrawal',
          action: 'read',
          subject: 'cc-god-only-page'
        },
        {
          title: 'Work Purchases',
          path: '/reports/work-purchases',
          action: 'read',
          subject: 'except-agent-page'
        },
        {
          title: 'Earnings Reports',
          action: 'read',
          subject: 'shared-page',
          children: [
            {
              title: 'Content Creator',
              path: '/reports/earnings/ContentCreator',
              action: 'read',
              subject: 'cc-page'
            },
            {
              title: 'Super Agent',
              path: '/reports/earnings/SuperAgent',
              action: 'read',
              subject: 'sa-page'
            },
            { title: 'Agent', path: '/reports/earnings/Agent', action: 'read', subject: 'agent-page' }
          ]
        },
        {
          title: 'VIP Bundles',
          path: '/reports/vip-bundles',
          action: 'read',
          subject: 'agent-page'
        },
        {
          title: 'Gold Coin Bundles',
          action: 'read',
          path: '/reports/gold-coin-bundles',
          subject: 'agent-page'
        },
        {
          title: 'User Reports',
          path: '/reports/user-reports',
          action: 'read',
          subject: 'agent-page'
        }
      ]
    },
    {
      title: 'Studio',
      icon: 'mdi:video-outline',
      action: 'read',
      subject: 'cc-god-only-page',
      children: [
        {
          title: 'Upload Content',
          path: '/studio/upload',
          action: 'read',
          subject: 'cc-god-only-page'
        },
        {
          title: 'Albums List',
          path: '/studio/album/album-list'
        },
        {
          title: 'Feeds Approval',
          path: '/studio/newsfeed/approval'
        },
        {
          title: 'Feeds List',
          path: '/studio/newsfeed',
          action: 'read',
          subject: 'cc-god-only-page'
        },
        {
          title: 'Feeds Status',
          path: '/studio/cc/post-status',
          action: 'read',
          subject: 'cc-only-page'
        },
        {
          title: 'Works Approval',
          path: '/studio/content'
        },
        {
          title: 'Works List',
          path: '/studio/video-list',
          action: 'read',
          subject: 'cc-god-only-page'
        },
        {
          title: 'Works Status',
          path: '/studio/cc/content-status',
          action: 'read',
          subject: 'cc-only-page'
        },
        {
          title: 'Work Groupings',
          path: '/studio/work-groupings'
        },
        {
          title: 'Work Groupings V2',
          path: '/studio/work-groupings-v2'
        }
      ]
    },
    {
      title: 'Bundles',
      icon: 'mdi:tag-multiple',
      children: [
        {
          title: 'VIP Bundles',
          path: '/bundles/pages/VIPBundlesPage'
        },
        {
          title: 'Gold Coin Bundles',
          path: '/bundles/pages/GoldCoinBundlesPage'
        }
      ]
    },
    {
      title: 'Mobile App',
      icon: 'mdi:source-branch',
      path: '/versions',
      action: 'read',
      subject: 'sa-page'
    },
    {
      title: 'Site Settings',
      icon: 'mdi:web',
      children: [
        {
          title: 'Advertisements',
          path: '/settings/pages/ads'
        },
        {
          title: 'Announcements',
          path: '/settings/pages/announcements'
        },
        {
          title: 'Featured Feeds',
          path: '/settings/pages/featuredfeeds'
        },
        {
          title: 'Privacy Policy',
          path: '/settings/pages/privacypolicy'
        },
        {
          title: 'Terms & Services',
          path: '/settings/pages/tos'
        }
      ]
    },
    {
      title: 'Settings',
      icon: 'mdi:cogs',
      children: [
        {
          title: 'Payment Channels',
          path: '/wallet'
        },
        {
          title: 'Security/Withdrawal',
          path: '/funds-withdrawal'
        }
      ]
    }
  ]
}

export default Navigation
