import authConfig from "@/configs/auth";
import axios from 'axios';
import { getHeaders } from "./cryptoJs";

const IS_SIT = process.env.NEXT_PUBLIC_APP_VARIANT === 'sit'

const client = (() => {
  return axios.create({
    baseURL: IS_SIT ? process.env.NEXT_PUBLIC_API_BASE_URL_SIT : process.env.NEXT_PUBLIC_API_BASE_URL_LOCAL,
    headers: {
      Accept: 'application/json'
    }
  })
})()

client.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 && window !== 'undefined') {
      window.localStorage.removeItem(authConfig.storageTokenKeyName)
      window.localStorage.removeItem(authConfig.userDataKeyname)

      window.location.reload()
    }

    return Promise.reject(error)
  }
)

client.interceptors.request.use(
  config => {
    const token = window.localStorage.getItem(authConfig.storageTokenKeyName)

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config;
  },
  error => {
    return Promise.reject(error)
  }
)

const request = async function (options, isExport) {
  //success handler
  const onSuccess = async function (response) {
    if (isExport) return response

    const {
      data: { data }
    } = response

    return data
  }

  //error handler
  const onError = function (error) {
    return Promise.reject(error.response)
  }

  options.headers = {
    ...options.headers,
    ...getHeaders()
  }

  //adding success and error handler to client
  return client(options).then(onSuccess).catch(onError)
}

export default request
